.navbar {
  width: 100%;
  color: white;
  font-size: 14px;
  position: fixed;
  top: 0;
  z-index: 999;
  background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.3) 50%);

  &.scrolled {
    background-color: var(--main-color);
  }

  .container {
    padding: 0px 20px; /* Adjust padding for responsiveness */
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    .mobile-menu-icon {
      display: flex;
      align-items: center;
      cursor: pointer;

      .MuiSvgIcon-root {
        font-size: 28px;
      }
    }

    .menu {
      display: flex;
      align-items: center;

      &.open {
        display: flex;
        justify-content: center; /* Center-align horizontally */
        align-items: center; /* Center-align vertically */

        .right {
          margin-left: auto; /* Keep the right part on the right side */
        }
      }

      .left {
        img {
          height: 50px;
          margin-right: 20px;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .icon {
          margin: 0px 15px;
          cursor: pointer;
        }

        .iconglobe {
          margin: 0px 15px;
          cursor: pointer;
          margin-top: 3px;
        }

        img {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          object-fit: cover;
          cursor: pointer;
        }

        .profile {
          .options {
            display: none;
            background-color: black;
            border-radius: 5px;
            position: absolute;
            top: 100%;
            right: 0;
          }

          span {
            padding: 10px;
            cursor: pointer;
          }

          &:hover {
            .options {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }

    .mobile-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: white;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      padding: 10px;
      border-radius: 5px;

      span {
        padding: 10px;
        cursor: pointer;
      }
    }

    .language-selector-options {
      position: absolute; /* Or use relative, depending on your layout */
      top: 50%; /* Adjust the vertical position as needed */
      transform: translateY(-50%);
      right: 0; /* Adjust the horizontal position as needed */
    }
  }
}

:root {
  --main-color: #ffffff;
}

.link {
  color: inherit;
  text-decoration: none;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: Poppins, sans-serif;
}

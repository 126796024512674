.form-success {
  text-align: center;
  margin-top: 200px;

  h2 {
    margin-top: 20px;
    color: #00a65a;
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
}

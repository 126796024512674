.form-wrapper {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 80px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-section {
  margin-bottom: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-message {
  background-color: pink;
  color: red; /* Set the color for error messages */
  font-size: 12px; /* Adjust font size for error messages */
  margin-top: -4px; /* Add a bit of space above the error message */
}

.error-message2 {
  background-color: pink;
  margin-left: 5px;
  color: red;
  font-size: 12px;
}

.Collapsible__trigger {
  font-size: 16px;
}

.text-header {
  font-size: larger;
  display: block;
  font-weight: bold;
  margin-top: 20px;
}

.text-body {
  font-size: larger;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.5;
}

.custom-banner {
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}

.title {
  color: white;
  border: none;
  padding: 20px 15px;
  width: 95%;
  background-color: #c7183d;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  border-radius: 12px;
}

.label {
  font-weight: bold;
}

.addIcon {
  padding-right: 5px;
  margin-top: 5px;
  transform: scale(1.5);
  margin-left: 5px;
}

.textbox {
  margin-top: 5px;
  padding: 5px;
  width: 95%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}

.Collapsefield {
  margin-top: 20px;
  margin-top: 15px;
}

.Collapsefield button {
  background-color: #831028;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.Collapsefield button:hover {
  background-color: #831028;
}

.Collapsible__trigger {
  font-size: 16px;
}

.disclaimerLabel {
  margin-top: -5px;
}

.disclaimer-checkbox {
  margin-top: 5px;
}

.btn--full {
  border: none;
  padding: 10px 15px;
  width: 100%;
  background-color: #c7183d;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  border-radius: 12px;
  border-top-right-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn--full:hover {
  background-color: #831028;
}

.invalid-form {
  background-color: gray;
}

.invalid-form:hover {
  background-color: lightgrey;
  cursor: not-allowed;
}

.btn--full-add {
  border: none;
  padding: 10px 15px;
  width: 100%;
  background-color: #c7183d;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  border-radius: 12px;
  border-top-right-radius: 0px;
  margin-top: 15px;
  cursor: pointer;
}

.required-asterisk {
  color: red;
  margin-left: 5px;
}

.permissions-section {
  display: flex;
  flex-direction: column;
}

.permission-checkbox {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.permission-checkbox label {
  margin-right: 5px;
}

/* Styling for checkboxes */
input[type="checkbox"] {
  margin-right: 5px;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .form-wrapper {
    padding: 10px;
  }
}

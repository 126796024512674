.meco-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: auto;
  padding: 40px 0px 20px 0px;
  transform-origin: top left;
  transform: skewY(0.3deg);
  position: relative;
}

.meco-logo img {
  max-width: 100%;
  max-height: 100%;
}
